import { css, makeStyles } from "@conexasaude/conexa-hero";

export const useStyles = makeStyles({
  root: ({ colors }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: ${colors.neutral[500]};
    height: 100dvh;
  `,

  loader: css`
    height: 100%;
    align-self: center;
    justify-self: center;
  `,
});
