import { Button, Center, Stack, Text } from "@conexasaude/conexa-hero";
import { UserErrorPrimary } from "@conexasaude/iconography";
import { Card, Container } from "@mantine/core";
import { FunctionComponent } from "react";
import { useSearchParams } from "react-router-dom";

const DuplicatedUser: FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  const shortenedToken = searchParams.get("shortened");

  const retry = () => {
    if (typeof window !== "undefined") {
      window.location.href = `${window.location.origin}/room/${shortenedToken}`;
    }
  };

  return (
    <Container h="100vh">
      <Center h="100%" px={16}>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Stack align="center" maw="480px" spacing={12} mb={16} pt={16}>
            <UserErrorPrimary size="small" />

            <Text variant="headingSmall" textAlign="center">
              Usuário duplicado detectado!
            </Text>
            <Text textAlign="center">
              Parece que você já está participando desta sala em outra aba ou
              dispositivo. Por favor, verifique e tente novamente.
            </Text>
            <Button leftIcon="rotate" onClick={retry}>
              Tentar novamente
            </Button>
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default DuplicatedUser;
