import { getTheme, Loader, ToastContainer } from "@conexasaude/conexa-hero";
import { roomEventListener, ZoomRoom } from "@conexasaude/zoom-sdk";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { RenderIf } from "@/components/RenderIf";
import {
  useMeetStatus,
  useSolveShortnedLink,
} from "@/data/queries/meet-queries";
import { queryClient } from "@/services/query-client";

import { useStyles } from "./styles";

export default function Room() {
  const styles = useStyles();

  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  const theme = getTheme();

  const { data, isLoading } = useSolveShortnedLink(token!);

  const { data: meetStatus } = useMeetStatus(data?.callId!);

  useEffect(() => {
    if (meetStatus?.status === "ENDED") {
      navigate("/finished");
    }
  }, [meetStatus, navigate]);

  useEffect(() => {
    roomEventListener.on("participantLeft", () => {
      queryClient.invalidateQueries({
        queryKey: ["meet-status"],
      });
    });

    roomEventListener.on("duplicate-user", () => {
      navigate(`/duplicated-user?shortened=${token}`);
    });

    return () => {
      roomEventListener.off("participantLeft");
      roomEventListener.off("duplicate-user");
    };
  }, [navigate, token]);

  return (
    <div css={styles.root}>
      <ToastContainer />

      <RenderIf condition={isLoading}>
        <Loader
          ringColor={theme.colors.brand.primary[100]}
          spinColor={theme.colors.background.white}
          size="large"
          styles={{
            root: styles.loader,
          }}
        />
      </RenderIf>

      <RenderIf condition={!isLoading}>
        <ZoomRoom
          credentialType="CREDENTIALS"
          env="HML"
          participantName={data?.participantName!}
          sessionId={data?.appointmentId?.toString()!}
          token={data?.token!}
          callId={data?.callId!}
          type="PATIENT"
        />
      </RenderIf>
    </div>
  );
}
