import { css, makeStyles } from "@conexasaude/conexa-hero";

export const useStyles = makeStyles({
  root: ({ colors }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: ${colors.brand.primary[500]};
    height: 100dvh;

    h1,
    p {
      color: ${colors.brand.primary[100]};
    }
  `,
});
