import { css } from "@conexasaude/conexa-hero";

export const globalStyles = () => css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    height: 100vh;
  }

  html,
  body,
  #root {
    height: 100vh;
    width: 100%;
  }
`;
