import { useQuery } from "@tanstack/react-query";

import meetService from "@/services/meet-service";

export const useSolveShortnedLink = (token: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ["solveShortnedLink", "token"],
    queryFn: async () => await meetService.solveShortnedLink(token),
    enabled: !!token,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
  };
};

export const useMeetStatus = (callId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["meet-status", callId],
    queryFn: async () => await meetService.getMeetStataus(callId),
    enabled: !!callId,
  });

  return {
    data,
    isLoading,
  };
};
