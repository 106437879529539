import { env } from "@/constants/env";

import { HttpClient } from "../api/http-client";
import { MeetStatusData, ShortnedTokenData } from "./types";

class MeetService {
  private httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(env.VITE_APP_API_CORE);
  }

  public async solveShortnedLink(token: string): Promise<ShortnedTokenData> {
    try {
      const response = await this.httpClient.get<ShortnedTokenData>(
        `/rooms/${token}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error fetching shortned link");
    }
  }

  public async getMeetStataus(callId: number): Promise<MeetStatusData> {
    try {
      const response = await this.httpClient.get<MeetStatusData>(
        `/calls/${callId}/status`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error fetching meet status");
    }
  }
}

export default new MeetService();
