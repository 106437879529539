import * as Sentry from "@sentry/react";

export const initSentry = (settings: Sentry.BrowserOptions) => {
  const currentOptions: Sentry.BrowserOptions = {
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
      }),
    ],
    ignoreErrors: [
      // Error timetout
      /timeout/,
      // NotFound error after clear DOM
      /Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node./,
    ],
    // Performance Monitoring
    tracesSampleRate: 1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  };

  const options = Object.assign(currentOptions, settings);

  return Sentry.init(options);
};
