import { Center, Stack, Text } from "@conexasaude/conexa-hero";
import { Card, Container } from "@mantine/core";
import { FunctionComponent } from "react";

import { ReactComponent as UserSuccess } from "@/assets/illustrations/user-success.svg";

const EndedMeet: FunctionComponent = () => {
  return (
    <Container h="100vh">
      <Center h="100%" px={16}>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Stack align="center" maw="480px" spacing={12} mb={16} pt={16}>
            <Text variant="headingSmall" textAlign="center">
              Esta sala foi encerrada com sucesso!
            </Text>
            <Text>Obrigado por utilizar nossa plataforma.</Text>
            <UserSuccess />
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default EndedMeet;
