import { z } from "zod";

const envSchema = z.object({
  VITE_APP_URL: z.string(),
  VITE_APP_API_CORE: z.string(),
  VITE_APP_ENV_NAME: z.enum(["HML", "PROD"]),
  VITE_SENTRY_DSN: z.string(),
});

export const env = envSchema.parse(import.meta.env);
