import { RouteObject } from "react-router-dom";

import DuplicateUser from "@/pages/DuplicateUser";
import EndedMeet from "@/pages/EndedMeet";
import Home from "@/pages/Home";
import Room from "@/pages/Room";

const MeetRoutes: RouteObject = {
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/room/:token",
      element: <Room />,
    },
    {
      path: "/finished",
      element: <EndedMeet />,
    },
    {
      path: "/duplicated-user",
      element: <DuplicateUser />,
    },
  ],
};

export default MeetRoutes;
