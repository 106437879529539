import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSolveShortnedLink } from "@/data/queries/meet-queries";

export const useHome = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const shortenedToken = searchParams.get("shortened");

  const meetSettings = useSolveShortnedLink(shortenedToken!);

  useEffect(() => {
    if (meetSettings?.data) {
      navigate(`/room/${shortenedToken}`);
    }
  }, [meetSettings, navigate, shortenedToken]);

  return {
    emptyRoom: !shortenedToken,
    isLoading: meetSettings.isLoading,
  };
};
