import { ResetStyles, ThemeProvider } from "@conexasaude/conexa-hero";
import { Global } from "@emotion/react";
import { MantineProvider } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { Fragment } from "react/jsx-runtime";
import { RouterProvider } from "react-router-dom";

import router from "./router";
import { queryClient } from "./services/query-client";
import { globalStyles } from "./styles/global";

function App() {
  const styles = globalStyles();

  return (
    <Fragment>
      <ResetStyles />
      <Global styles={styles} />

      <ThemeProvider theme="cnx">
        <MantineProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </MantineProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
