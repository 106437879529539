import { createBrowserRouter } from "react-router-dom";

import MeetRoutes from "./meet-routes";
import RedirectRoute from "./redirect";

const router = createBrowserRouter([
  {
    children: [MeetRoutes],
  },
  RedirectRoute,
]);

export default router;
