import {
  ConexaLogo,
  Flex,
  getTheme,
  Loader,
  Text,
} from "@conexasaude/conexa-hero";

import { RenderIf } from "@/components/RenderIf";
import { useHome } from "@/hooks/useHome";

import { useStyles } from "./styles";

export default function Home() {
  const styles = useStyles();
  const theme = getTheme();

  const { emptyRoom, isLoading } = useHome();

  return (
    <div css={styles.root}>
      <Flex
        align="center"
        direction="column"
        justify="center"
        h="100%"
        gap={theme.spacing.xs}
      >
        <RenderIf condition={emptyRoom}>
          <Text variant="headingSmall" renderAs="p">
            Nenhuma sala informada.
          </Text>
        </RenderIf>

        <RenderIf condition={isLoading}>
          <Loader
            ringColor={theme.colors.brand.primary[100]}
            spinColor={theme.colors.background.white}
            size="large"
          />
        </RenderIf>

        <Flex direction="column" pos="absolute" bottom={theme.spacing.xs}>
          <Text variant="microCopy" renderAs="p">
            Powered by
          </Text>
          <ConexaLogo base="colorful" />
        </Flex>
      </Flex>
    </div>
  );
}
