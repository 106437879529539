import { Fragment, FunctionComponent, PropsWithChildren } from "react";

type RenderIfProps = {
  condition: boolean;
};

export const RenderIf: FunctionComponent<PropsWithChildren<RenderIfProps>> = (
  props
) => {
  const { condition, children } = props;

  return condition ? <Fragment>{children}</Fragment> : null;
};
