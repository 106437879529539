import { initSentry } from "@conexasaude/sentry";

import { env } from "@/constants/env";

const isProduction = env.VITE_APP_ENV_NAME === "PROD";

initSentry({
  dsn: env.VITE_SENTRY_DSN,
  enabled: isProduction,
});
